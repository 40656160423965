import React from "react";
import './Faq.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../Images/Logo.png'
import closeMenu from '../../Images/closeMenu.png'

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (


        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Anaheim Garage Door | Questions and Answer</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Anaheim Garage Door | Questions and Answer" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Anaheim Garage Door | Questions and Answer" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.anaheimgaragedoor.org/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Anaheim Garage Door Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to garage door services, secure your home." data-react-helmet="true" />                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Anaheim Garage Door Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to garage door services, secure your home." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Anaheim Garage Door | Questions and Answer" data-react-helmet="true" />
                <meta name="keywords" content="Garage Door Broken Spring, Garage Door Spring Repair, Garage Door Broken Rollers, Garage Door Broken Cables, Garage Door Broken Hinges, Garage Door Broken Drums, Garage Door Off Track, Damage Garage Door Section, New Garage Door Installation, Garage Door Opener Won't Open Or Close, Garage Door Opener Remote Is Not Working, Garage Door Opener Keypad Is Not Working, Garage Door Maintenance, Garage Door Installation, Garage Door Opener Stripped Gear, Garage Door Opener Installation, Garage Door Opener Repair, Garage Door Repair, Garage Door Services, Garage Door Near me, Garage Door Spring Replacement, Garage Door Replacement, Garage Door Opener Chain Repair, Garage Door Opener Belt Repair, Garage Door Residential, Commercial Garage Door, Residential Garage Doors" data-react-helmet="true" />
            </Helmet>
            <div className="FaqMainBanner">
                <h1>Garage Door Questions</h1>
                <h2>For professional Garage Door Off Track services, contact Anaheim Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Garage Doorsafe and functional.</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#garage-door-repair">Garage Door Repair</a>
                    <a href="/faq#garage-door-services">Garage Door Services</a>
                    <a href="/faq#garage-door-broken-spring">Garage Door Broken Spring</a>
                    <a href="/faq#garage-door-off-track">Garage Door Off Track</a>
                    <a href="/faq#residential-garage-doors">Residential Garage Doors</a>
                    <a href="/faq#garage-door-opener">Garage Door Opener</a>
                    <a href="/faq#garage-door-installation">Garage Door Installation</a>
                </div>

            </div>
            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>Anaheim Garage Door promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional Garage Door Off Track services, contact Anaheim Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Garage Doorsafe and functional.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="garage-door-repair">Garage Door Repair</h6>
                        {faqlistFilter.filter(a => a.category == "Garage Door Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="" />
                                    <h2>Anaheim Garage Door promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional Garage Door Off Track services, contact Anaheim Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Garage Doorsafe and functional.</p>
                        </div>
                        <h6 id="garage-door-services">Garage Door Services</h6>
                        {FaqList.filter(a => a.category == "Garage Door Services").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="garage-door-broken-spring">Garage Door Broken Spring</h6>
                        {FaqList.filter(a => a.category == "Garage Door Broken Spring").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="garage-door-off-track">Garage Door Off Track</h6>
                        {FaqList.filter(a => a.category == "Garage Door Off Track").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="residential-garage-doors">Residential Garage Doors</h6>
                        {FaqList.filter(a => a.category == "Residential Garage Doors").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="garage-door-opener">Garage Door Opener</h6>
                        {FaqList.filter(a => a.category == "Garage Door Services").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                        <h6 id="garage-door-installation">Garage Door Installation</h6>
                        {FaqList.filter(a => a.category == "Garage Door Installation").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>

                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#garage-door-repair" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#garage-door-repair">Garage Door Repair</a>
                        <a style={location.hash == "#garage-door-services" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#garage-door-services">Garage Door Services</a>
                        <a style={location.hash == "#garage-door-broken-spring" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#garage-door-broken-spring">Garage Door Broken Spring</a>
                        <a style={location.hash == "#garage-door-off-track" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#garage-door-off-track">Garage Door Off Track</a>
                        <a style={location.hash == "#residential-garage-doors" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#residential-garage-doors">Residential Garage Doors</a>
                        <a style={location.hash == "#garage-door-opener" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#garage-door-opener">Garage Door Opener</a>
                        <a style={location.hash == "#garage-door-installation" ? { "borderBottom": "2px solid #0157ff" } : null} href="/faq#garage-door-installation">Garage Door Installation</a>
                    </div>
                    <h1>Search for question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)

                            }} type="text" placeholder="Search..." />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="" />
                            :
                            null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>

                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>

                        </a>
                    )}
                </div>
            </div>
            <div className="FaqApply">
                <h1>For professional Garage Door Off Track services, contact Anaheim Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Garage Doorsafe and functional.</h1>
                <div className="FaqApplyBox">
                    <img src={Review} />
                    <h2> Submit your application within minutes <br /> No commitment required</h2>
                    <a href="/apply" >Apply Now</a>
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

